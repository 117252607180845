import PropTypes from "prop-types";
import React from "react";
import { useModal } from "../context/context";

const CongressCard = ({ congress }) => {
	const { showModal } = useModal();

	const handleExternalLinkClick = e => {
		e.preventDefault();
		showModal(congress.link);
	};

	return (
		<div className="card card-bordered card-congress">
			<div className="row align-items-center justify-content-center">
				<div className="col-sm-10">
					<h4 className="card-congress-heading">{congress.name}</h4>
					<p className="card-congress-date">
						<strong>{congress.date}</strong>
					</p>
					<hr />
					<p style={{ textAlign: "left" }}>{congress.body}</p>
					<button
						onClick={e => handleExternalLinkClick(e)}
						className={
							congress.featured ? "btn btn-primary" : "btn btn-secondary"
						}
					>
						{congress.button ? congress.button : "Register Here"}
					</button>
				</div>
			</div>
		</div>
	);
};

CongressCard.propTypes = {
	congress: PropTypes.object.isRequired,
};

export default CongressCard;
