import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import background from "../images/header-image-01.png";
import kidneys from "../images/kidneys-image.png";
import { Link } from "gatsby";
import CongressCard from "../components/congressCard";
import References from "../components/references";
import { formatEventDate } from "../utils/index.jsx";

const IndexPage = () => {
	const [featuredEvent, setFeaturedEvent] = useState(undefined);

	const isUpcoming = eventDate => {
		//console.log("Event Date: " + eventDate);
		var today = Date.now();
		var congress = new Date(eventDate).getTime();

		//console.log("Today: " + today + " || Congress: " + congress);

		//if (today <= congress) console.log("It is upcoming!");

		return today <= congress;
	};

	useEffect(() => {
		async function fetchData() {
			try {
				const result = await axios.get(
					"https://d2zzqzqmnbfbm9.cloudfront.net/congress-events"
				);
				const featured = [];
				if (!result.data.errors) {
					result.data.forEach(c => {
						if (isUpcoming(c.event_end_date__c) && c.event_featured__c) {
							featured.push(c);
						}
					});
					if (featured) {
						setFeaturedEvent(featured[0]);
					}
					// console.log("process.env.GATSBY_STAGE: " + process.env.GATSBY_STAGE);
					// var feature = result.data.filter(
					// 	c =>
					// 		c.event_featured__c && isUpcoming(c.event_end_date__c) &&
					// 		((process.env.GATSBY_STAGE &&
					// 			c.site_status__c[0].toLowerCase() === "staging__c") ||
					// 			c.site_status__c[0].toLowerCase() === "production__c")
					// );
					// console.log("Feature: " + feature);
					// if (feature) {
					// 	console.log("Feature: " + feature);
					// 	setFeaturedEvent(feature[0]);
					// }
				}
			} catch (e) {
				// console.log(e);
			}
		}

		fetchData();
	}, []);

	useEffect(
		() => {
			function pulseHeader() {
				var d = document.getElementById('fda-preheader');
				setTimeout(function () {
					d.classList.add('on');
				}, 250);

			}
			pulseHeader();
		}
	);

	return (
		<Layout contact>
			<SEO title="Home" />
			<Hero background={background} large={true}>
				<div className="col col-sm-7">
					<h1 className="hero-heading">
						Welcome to the Medical Engagement Hub
					</h1>
					<p className="hero-copy home">
						At Akebia, we are committed to our purpose—to better the life of
						each person impacted by kidney disease. That's why we invest in
						groundbreaking scientific research that focuses on advancing patient
						care.
					</p>
					<p className="hero-copy home">
						This hub is your gateway to scientific education and research to
						advance your understanding of kidney disease complications.
					</p>
				</div>
			</Hero>
			<Hero background={kidneys}>
				<div className="col-sm-6">
					<h2 className="hero-heading">Kidney Disease</h2>
					<p className="hero-copy">
						Approximately 35.5 million people in the U.S. are living with chronic
						kidney disease.<sup>1</sup> Every day, we leverage our scientific
						expertise and innovative thinking to develop clinical advances that
						better the lives of those impacted by chronic kidney disease and its
						complications.
					</p>
					<Link to="our-science/kidney-disease" className="btn btn-primary">
						Learn More
					</Link>
				</div>
			</Hero>
			<div className="upcoming">
				<div className="container">
					<div className="row">
						<div className="col">
							<h3 className="upcoming-heading">Upcoming at Akebia</h3>
							{featuredEvent && (
								<CongressCard
									congress={{
										name: featuredEvent.event_title__c,
										date: formatEventDate(featuredEvent),
										body: featuredEvent.event_description__c,
										button: featuredEvent.event_button_text__c,
										link: featuredEvent.event_button_link__c,
										featured: true,
									}}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			<References>
				<p>
					<strong>1.</strong> Centers for Disease Control and Prevention.{" "}
					<a
						href="https://www.cdc.gov/kidneydisease/pdf/CKD-Factsheet-H.pdf"
						target="_blank"
						rel="noreferrer"
					>
						https://www.cdc.gov/kidneydisease/pdf/CKD-Factsheet-H.pdf
					</a>
					. Accessed March 2024.
				</p>
			</References>
		</Layout>
	);
};

export default IndexPage;
