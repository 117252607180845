import PropTypes from "prop-types";
import React from "react";

const references = ({ children }) => (
	<div className="references">
		<div className="container">
			<div className="row">
				<div className="col">
					<p className="references-heading">References</p>
					<div className="references-body">{children}</div>
				</div>
			</div>
		</div>
	</div>
);

references.propTypes = {
	children: PropTypes.node.isRequired,
};

export default references;
